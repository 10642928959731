import React, { useState } from 'react';
import styled from '@emotion/styled';
import { getPageTemplate } from 'components/agility-pageTemplates';
import { handlePreview } from '@agility/nextjs';
import { useRouter } from 'next/router';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
// import Error from 'next/error';
import Custom404 from '../../pages/404';
import PreviewBar from './PreviewBar';
import SEO from './SEO';
import SiteHeader from './SiteHeader';
import SiteFooter from './SiteFooter';
import FeaturedPost from '../agility-pageModules/FeaturedPost';
import LoadingWidget from './LoadingWidget';
import { useAppInsightsTrackPageView } from 'appinsights/clientAppInsights';

// set up handle preview
const isPreview = handlePreview();

function Layout(props) {
  const { page, sitemapNode, dynamicPageItem, notFound, pageTemplateName } = props;
  const [showPreview, setShowPreview] = useState(false);
  const addHeaderMarkup = dynamicPageItem?.seo?.metaHTML ?? page?.seo?.metaHTML;

  /*eslint-disable*/
  if (typeof window !== 'undefined') {
    useAppInsightsTrackPageView();
  }
  /*eslint-enable*/

  // Get page template name
  const AgilityPageTemplate = getPageTemplate(pageTemplateName);

  if (dynamicPageItem?.seo?.metaDescription) {
    page.seo.metaDescription = dynamicPageItem.seo.metaDescription;
  }

  const headerClick = (e) => {
    if (e.altKey) {
      setShowPreview(!showPreview);
    }
  };

  // If the page is not yet generated, this will be displayed
  // initially until getStaticProps() finishes running
  const router = useRouter();
  if (router.isFallback) {
    return <LoadingWidget message='Loading Page' />;
  }

  // if page not found, throw 404
  if (notFound === true) {
    // return <Error statusCode={404} />;
    return <Custom404 />;
  }

  return (
    <React.Fragment>
      <SEO
        title={sitemapNode?.title}
        description={page.seo.metaDescription}
        keywords={page.seo.metaKeywords}
        metaHTML={page.seo.metaHTML}
        additionalHeaderMarkup={addHeaderMarkup}
      />
      <div id='site-wrapper'>
        {isPreview && <LoadingWidget message='Loading Preview Mode' />}
        {!isPreview && (
          <Container>
            {showPreview && <PreviewBar {...props} />}
            <div style={{ overflow: 'hidden' }}>
              <div className='flex flex-col min-h-screen'>
                <SiteHeader {...props} handleHeaderClick={headerClick} />
                <main>
                  <AgilityPageTemplate {...props} />
                </main>
                {/* <FeaturedPost /> */}
                <SiteFooter {...props} style={{ position: 'absolute', bottom: 0 }} />
              </div>
            </div>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
}

export default Layout;

const Container = styled('div')(({ theme }) => ({
  height: '100vh',
}));
