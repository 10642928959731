import React, { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import CardGrid from 'components/common/subcomponents/CardGrid';
import PleaseWait from 'components/common/PleaseWait';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { KitGridContainer, KitGridItem, KitButton, KitCard, KitCardBody } from '@boystownorg/bi-cms-component-lib';
import { renderHTML } from '@agility/nextjs';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { logResourceAccess } from 'services/gatedResourceUtils';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideoDialogWithX from './pageComponents/VideoDialogWithX';

const VideoLists = (props) => {
  const { customData, module, page } = props;

  const theme = useTheme();

  const [showVideoDialog, setShowVideoDialog] = useState(false);
  const [showMobileVideoDialog, setShowMobileVideoDialog] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [videoList, setVideoList] = useState([]);
  const [showVideos, setShowVideos] = useState(typeof window !== 'undefined' && window.screen.width > 768 ? 6 : 3);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let array1 = [];
    let array2 = [];
    // Featured video list is sorted by the specified sort order.
    array1 = module.fields.featuredVideoList.sort((a, b) => {
      if (parseInt(a.fields.sortOrder, 10) > parseInt(b.fields.sortOrder, 10)) return 1;
      if (parseInt(a.fields.sortOrder, 10) < parseInt(b.fields.sortOrder, 10)) return -1;
      return 0;
    });
    // The rest of the videos are sorted by the date modified.
    array2 = module.fields.videoList.sort((a, b) => {
      if (b.properties.modified > a.properties.modified) return 1;
      if (b.properties.modified < a.properties.modified) return -1;
      return 0;
    });
    const list = array1.concat(array2);
    setVideoList(list);
  }, [module]);

  const displayReadMore = (str, len) => {
    if (str.length > len) {
      return true;
    }
    return false;
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  const setVideoDialog = async (video) => {
    setSelectedVideo(video);
    setShowVideoDialog(true);

    await logResourceAccess('ask the trainer', video.fields?.title, video.fields?.videoUrl?.href);
  };

  const setMobileVideoDialog = async (video) => {
    setSelectedVideo(video);
    window.open(video.fields.videoUrl.href, '_blank');

    await logResourceAccess('ask the trainer', video.fields?.title, video.fields?.videoUrl?.href);
  };

  const hideVideoDialog = () => {
    setShowVideoDialog(false);
  };

  const hideMobileVideoDialog = () => {
    setShowMobileVideoDialog(false);
  };

  const handleShowMore = () => {
    setIsLoading(true);
    setTimeout(() => {
      setShowVideos(showVideos + 6);

      const yourElement = document.getElementById('video_' + showVideos) || document.getElementById('moreButton');
      const scrollLoc = yourElement.getBoundingClientRect().top + (window.scrollY - showVideos * 3);

      window.scrollTo({ top: scrollLoc, behavior: 'smooth' });

      setIsLoading(false);
    }, 2000);
  };

  const Frame = styled.div`
    background: linear-gradient(180deg, #d9d9d9 0%, rgba(217, 217, 217, 0.2) 30.21%);
    scroll-behavior: smooth;
  `;

  const VideoContainer = styled.div`
    flex-grow: 0;
    flex-shrink: 1;
    .video {
      aspect-ratio: 16 / 9;
      width: 800px;
    }
  `;

  const EmbedContainer = styled.div`
    @media screen and (min-width: 992px) {
      width: 800px;
    }
    @media screen and (max-width: 991px) {
      display: none;
    }
  `;

  const BrowserLink = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 899px) {
    }
    @media screen and (min-width: 900px) {
      display: none;
    }
  `;

  const ShadowCard = styled.div`
    position: relative;
    :before,
    :after {
      z-index: -1;
      position: absolute;
      content: '';
      bottom: 40px;
      left: 10px;
      width: 50%;
      top: 80%;
      max-width: 300px;
      background: #777;
      -webkit-box-shadow: 0 15px 10px #777;
      -moz-box-shadow: 0 15px 10px #777;
      box-shadow: 0 15px 10px #777;
      -webkit-transform: rotate(-3deg);
      -moz-transform: rotate(-3deg);
      -o-transform: rotate(-3deg);
      -ms-transform: rotate(-3deg);
      transform: rotate(-3deg);
    }
    &:after {
      -webkit-transform: rotate(3deg);
      -moz-transform: rotate(3deg);
      -o-transform: rotate(3deg);
      -ms-transform: rotate(3deg);
      transform: rotate(3deg);
      right: 10px;
      left: auto;
    }
  `;

  return (
    <Frame>
      <Box
        sx={{
          width: '100%',
          ml: 'auto',
          mr: 'auto',
          maxWidth: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
        }}
      >
        <CardGrid
          style={{
            margin: '0 5px',
            gap: '0 1.40em',
            paddingBottom: 15,
          }}
        >
          {videoList.map(
            (video, index) =>
              index < showVideos && (
                <ShadowCard key={video.contentId + '_' + index}>
                  <KitCard id={'video_' + index} style={{ width: '22rem', borderRadius: 0 }}>
                    <React.Fragment>
                      <KitCardBody style={{ padding: '1.00rem' }}>
                        <ImageContainer onClick={() => setVideoDialog(video)}>
                          <ModuleImg
                            style={{ cursor: 'pointer', height: '195px', width: 'auto' }}
                            alt={video.fields?.title}
                            src={video.fields?.videoThumbnail?.url}
                          />
                        </ImageContainer>
                        <LaunchButtonContainer>
                          <KitButton
                            round
                            onClick={() => setVideoDialog(video)}
                            style={{
                              backgroundColor: theme.palette.brandedAqua.main,
                              fontSize: '1.00rem',
                              fontWeight: 300,
                              height: '40px',
                            }}
                          >
                            <span style={{ marginLeft: 10, fontSize: '1.00rem' }}>
                              Watch <VideocamIcon color='white' style={{ marginLeft: 10, marginBottom: 3, width: '28px', height: '20px' }} />
                            </span>
                          </KitButton>
                        </LaunchButtonContainer>
                        <MobileImageContainer onClick={() => setMobileVideoDialog(video)}>
                          <ModuleImg
                            style={{ cursor: 'pointer', height: '195px', width: 'auto' }}
                            alt={video.fields?.title}
                            src={video.fields?.videoThumbnail?.url}
                          />
                        </MobileImageContainer>
                        <LaunchMobileButtonContainer>
                          <a href={selectedVideo && video.fields.videoUrl && video.fields.videoUrl.href} target='_blank' rel='noreferrer'>
                            <KitButton
                              round
                              onClick={() => setMobileVideoDialog(video)}
                              style={{
                                backgroundColor: theme.palette.brandedAqua.main,
                                fontSize: '1.00rem',
                                fontWeight: 300,
                                height: '40px',
                              }}
                            >
                              <span style={{ marginLeft: 10, fontSize: '1.00rem' }}>
                                Watch <VideocamIcon color='white' style={{ marginLeft: 10, marginBottom: 3, width: '28px', height: '20px' }} />
                              </span>
                            </KitButton>
                          </a>
                        </LaunchMobileButtonContainer>
                      </KitCardBody>
                    </React.Fragment>
                  </KitCard>
                </ShadowCard>
              )
          )}
          {showVideos <= videoList.length && (
            <MoreButton id='moreButton'>
              <KitButton round color='success' onClick={(e) => handleShowMore()}>
                {isLoading ? 'Loading...' : 'View More'}
              </KitButton>
            </MoreButton>
          )}
        </CardGrid>
        <PleaseWait isLoading={isLoading} />
      </Box>
      <VideoDialogWithX
        size='md'
        content={
          <DialogContent>
            <KitGridContainer direction='row'>
              <KitGridItem xs={12} align='left'>
                {selectedVideo && selectedVideo.fields && (
                  <VideoContainer>
                    <EmbedContainer className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(selectedVideo.fields.embedVideo)} />
                  </VideoContainer>
                )}
              </KitGridItem>
            </KitGridContainer>
          </DialogContent>
        }
        ariaLabel='View Video'
        showValue={showVideoDialog}
        showMethod={hideVideoDialog}
      />
    </Frame>
  );
};

export default VideoLists;

const RoundIcon = styled('span')(({ theme }) => ({
  borderRadius: '5px',
  backgroundColor: theme.palette.success.main,
  color: theme.palette.common.white,
}));

const ImageContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    height: '12rem',
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const MobileImageContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'auto',
    height: '12rem',
  },
}));

const ModuleImg = styled('img')(({ theme }) => ({
  borderRadius: '6px',
  display: 'block',
  width: '20rem',
  height: 'auto',
}));

const LaunchButtonContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '10px',
    paddingBottom: '5px',
    '& button': {
      width: '150px',
    },
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}));

const LaunchMobileButtonContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '10px',
    paddingBottom: '5px',
    '& button': {
      width: '150px',
    },
  },
}));

const ButtonContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
}));

const MoreButton = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '5px 0 20px 0',
  width: '100%',
}));
