import React, { useState } from 'react';
import { renderHTML } from '@agility/nextjs';
import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Typography } from '@mui/material';
import { KitAccordion, KitButton } from '@boystownorg/bi-cms-component-lib';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  position: relative;
`;

function TabPanel(props) {
  const { children, value, index, tabName, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`${tabName}-panel`} aria-labelledby={tabName} {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const SupportFAQ = ({ module }) => {
  const { fields } = module;

  const [tabIndex, setTabIndex] = useState(0);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        ml: 'auto',
        mr: 'auto',
        width: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          width: '100%',
          ml: 'auto',
          mr: 'auto',
          width: { sm: '540px', md: '768px', lg: '1180px', xl: '1180px' },
        }}
      >
        <Tabs value={tabIndex} onChange={(e, nv) => setTabIndex(nv)} aria-label='Support Tab'>
          <Tab label='Product Support' id='product-support' aria-controls='product-support-tabpanel' />
          <Tab label='Technical Support' id='technical-support' aria-controls='technical-support-tabpanel' />
        </Tabs>
      </Box>

      <Container
        style={{
          backgroundImage: fields.backgroundImage && fields.backgroundImage.url.length > 0 && `url(${fields.backgroundImage.url})`,
          backgroundColor: `rgba(191, 187, 175, .15)`, // #BFB8AF 0.15
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            ml: 'auto',
            mr: 'auto',
            width: { sm: '585px', md: '815px', lg: '1225px', xl: '1225px' }, // These are bigger to counteract the defined gap in the Frame.
          }}
        >
          <TabPanel value={tabIndex} index={0} tabName='product-support'>
            <Typography sx={{ mb: '30px' }}>{fields.productSupportDescription}</Typography>
            <Frame>
              <AccordianContainer>
                <KitAccordion
                  collapses={fields.productSupportFAQ
                    ?.sort((a, b) => {
                      if (parseInt(a.fields.sortOrder, 10) > parseInt(b.fields.sortOrder, 10)) return 1;
                      if (parseInt(a.fields.sortOrder, 10) < parseInt(b.fields.sortOrder, 10)) return -1;
                      return 0;
                    })
                    .map((f) => {
                      return {
                        content: <Answer className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(f.fields?.answer)} />,
                        title: f.fields?.question,
                      };
                    })}
                />
              </AccordianContainer>
              <HelpFormContainer>
                <FormHeader
                  iconUrl={fields.productSupportFormIcon?.url}
                  iconLabel={fields.productSupportFormIcon?.label}
                  title={fields.productSupportFormTitle}
                />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '15px 0' }}>
                  <a href='contact-us' rel='noopener noreferrer'>
                    <KitButton style={{ width: '130px' }} size='small' color='success' round variant='outlined'>
                      CONTACT US
                    </KitButton>
                  </a>
                </div>
              </HelpFormContainer>
            </Frame>
          </TabPanel>

          <TabPanel value={tabIndex} index={1} tabName='technical-support'>
            <Typography sx={{ mb: '30px' }}>{fields.technicalSupportDescription}</Typography>
            <Frame>
              <AccordianContainer>
                <KitAccordion
                  collapses={fields.technicalSupportFAQ?.map((f) => {
                    return {
                      content: <Answer className='prose max-w-full mx-auto' dangerouslySetInnerHTML={renderHTML(f.fields?.answer)} />,
                      title: f.fields?.question,
                    };
                  })}
                />
              </AccordianContainer>
              <HelpFormContainer>
                <FormHeader
                  iconUrl={fields.technicalSupportFormIcon?.url}
                  iconLabel={fields.technicalSupportFormIcon?.label}
                  title={fields.technicalSupportFormTitle}
                />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '15px 0' }}>
                  <a href='contact-us' rel='noopener noreferrer'>
                    <KitButton style={{ width: '130px' }} size='small' color='success' round variant='outlined'>
                      CONTACT US
                    </KitButton>
                  </a>
                </div>
              </HelpFormContainer>
            </Frame>
          </TabPanel>
        </Box>
      </Container>
    </div>
  );
};

export default SupportFAQ;

const FormHeader = (props) => {
  return (
    <>
      <Box sx={{ display: 'flex', backgroundColor: '#fff', justifyContent: 'center', mt: '10px', gap: '25px' }}>
        <img src={props.iconUrl} alt={props.iconlabel} height='55px' width='55px' />
        <Typography fontSize='28px' fontWeight={600} width='65%'>
          {props.title}
        </Typography>
      </Box>
      <Box sx={{ borderBottom: '1px dashed #000', width: '90%', margin: '5px auto' }}>&nbsp;</Box>
    </>
  );
};

const Frame = styled('Box')(({ theme }) => ({
  display: 'flex',
  gap: '20px',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'flex-start',
  },
  [theme.breakpoints.down('md')]: {
    justifyContent: 'flex-start',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

const AccordianContainer = styled('Box')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: '0 10px',
  [theme.breakpoints.up('md')]: {
    width: '70%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const HelpFormContainer = styled('Box')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  [theme.breakpoints.up('md')]: {
    width: '30%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
}));

const Answer = styled('div')(({ theme }) => ({
  color: theme.palette.common.black,
  margin: 5,
}));
