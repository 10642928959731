// package imports
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';

// local imports
import { OTHER_SCHOOL } from 'services/constants';
import { displayActivity } from 'services/observationUtils';

const ObservationHeaderDetail = ({ observation, observationMetrics }) => {
  const theme = useTheme();

  return (
    <Box sx={{ fontSize: '16px', fontWeight: 400, lineHeight: '20px', color: theme.palette.white.main }}>
      <div>Observation time: {observationMetrics.observationMinutes.toFixed(0)} minutes</div>
      <div>{observation.classroom_type}</div>
      <div>
        {observation.teacher} {observation.room_number && observation.room_number.length > 0 ? '(Room ' + observation.room_number + ')' : ''}
      </div>
      <div>{observation.agency_id === OTHER_SCHOOL.id ? observation.district_name : observation.agency_name}</div>
      {observation.grade && <div>{observation.grade}</div>}
      {observation.subject && observation.subject !== 'Other' && <div>{observation.subject}</div>}
      {observation.other_subject && <div>{observation.other_subject}</div>}
      {displayActivity(observation)}
      <div>Observed by: {observation.observer_name ?? 'Unknown'}</div>
      <div>Student Count: {observation.number_of_students}</div>
    </Box>
  );
};

export default ObservationHeaderDetail;
