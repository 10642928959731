import { InputBase, Button } from '@mui/material';
import styled from '@emotion/styled';

export const ObsStyles = (theme) => ({
  label: {
    '& .MuiInputLabel-root': {
      color: theme.palette.primary.dark,
      '&.Mui-focused': {
        color: theme.palette.primary.dark,
      },
    },
    '.MuiInputLabel-outlined': {
      color: theme.palette.primary.dark,
      '&.Mui-focused': {
        color: theme.palette.primary.dark,
      },
    },
  },
  select: {
    '& .MuiInputBase-root.Mui-disabled': {
      '& > fieldset': {
        borderColor: theme.palette.gold.light,
      },
    },
    '& .MuiInputBase-root.Mui-focused': {
      '& > fieldset': {
        borderColor: theme.palette.gold.main,
      },
    },
    '& .MuiBase-root.Mui-focused': {
      '& > fieldset': {
        borderColor: theme.palette.gold.main,
      },
    },
    '.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.gold.main,
    },
    '.MuiOutlinedInput-root': {
      backgroundColor: theme.palette.white.main,
    },
    // '& .MuiOutlinedInput-root:hover': {
    //   '& > fieldset': {
    //     borderColor: theme.palette.gold.dark,
    //   },
    // },
  },
});

export const PromptContainer = styled('div')(({ theme, promptType }) => ({
  borderRadius: '8px',
  backgroundColor: `${theme.palette[`obs${promptType}`].light}`,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  flexeWrap: 'nowrap',
  height: '514px',
  width: '270px',
  marginTop: '1rem',
  '.prompt-title-badge': {
    top: '-15px',
    '.prompt-title-box': {
      border: `1px solid ${theme.palette[`obs${promptType}`].light}`,
      borderRadius: '12px',
      width: '196px',
      height: '44px',
      position: 'relative',
      backgroundColor: theme.palette.white.main,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  // edit mode - without badge
  '> .prompt-title-box': {
    border: `1px solid ${theme.palette[`obs${promptType}`].light}`,
    borderRadius: '12px',
    width: '196px',
    height: '44px',
    position: 'relative',
    top: '-15px',
    backgroundColor: theme.palette.white.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const PromptButton = styled(Button)(({ theme, promptType, round = false }) => ({
  border: `solid .5px ${theme.palette[`obs${promptType}`].dark}`,
  borderRadius: round ? '50%' : '12px',
  width: round ? '48px' : '94px',
  minWidth: round ? '48px' : '94px',
  height: '48px',
  backgroundColor: theme.palette[`obs${promptType}`].xLight,
  color: '#000',
}));

export const PromptRadio = styled('div')(({ theme, promptType, round = false }) => ({
  'input[type="radio"]': {
    display: 'none',
    '&:checked + label': {
      backgroundColor: theme.palette[`obs${promptType}`].dark,
      color: theme.palette.white.main,
    },
    '+ label': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: `solid .5px ${theme.palette[`obs${promptType}`].dark}`,
      borderRadius: round ? '50%' : '12px',
      width: round ? '48px' : '94px',
      minWidth: round ? '48px' : '94px',
      height: '48px',
      backgroundColor: theme.palette[`obs${promptType}`].xLight,
      color: '#000',
    },
  },
}));

export const TimelineBadge = styled('span')(({ theme }) => ({
  backgroundColor: '#E1E5EA',
  color: '#4D4D4D',
  borderRadius: '16px',
  padding: '2px 8px',
  fontSize: '14px',
  lineHeight: '18px',
  fontWeight: 400,
  marginRight: '8px',
}));

export const Benchmarks = styled('div')(({ theme }) => ({
  fontWeight: 600,
  fontSize: '16px',
  lineHeight: '20px',
  color: theme.palette.text.mediumDark,
  paddingBottom: '5px',
  paddingTop: '5px',
  '.right-align': {
    textAlign: 'right',
  },
  '.pie': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '.mo': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '.four-column-section': {
    margin: '.5rem 1rem',
    padding: '.5rem 1rem',
    backgroundColor: theme.palette.common.white,
    borderRadius: '8px',
    display: 'grid',
    gridTemplateColumns: '25% 20% 20% 35%',
    gap: '.2rem',
  },
  '.three-plus-one': {
    display: 'grid',
    gridTemplateColumns: '68% 32%',
    //gap: '.2rem',
    '.three-column-section': {
      margin: '.5rem 1rem',
      padding: '.5rem 1rem',
      backgroundColor: theme.palette.common.white,
      borderRadius: '8px',
      display: 'grid',
      gridTemplateColumns: '39% 30% 28%',
      //gap: '.2rem',
    },
    '.one-column-section': {
      margin: '.5rem 1rem',
      padding: '.5rem 1rem',
      backgroundColor: theme.palette.common.white,
      borderRadius: '8px',
      '.mo-value': {
        fontWeight: 600,
        fontSize: '28px',
        lineHeight: '35px',
        color: theme.palette.obsBlue.main,
      },
    },
  },
}));
