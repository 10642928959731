'use client';

// package imports
import { useTheme } from '@emotion/react';
import { Box } from '@mui/material';
import { useRef } from 'react';
import { Transition } from 'react-transition-group';
import { useMeasure } from 'react-use';

// local imports
import { useAccordionItem } from './useAccordion';

export function AccordionItem({ children, index, title }) {
  const theme = useTheme();
  const { isActive, toggle } = useAccordionItem(index);
  const [sizeRef, size] = useMeasure();
  const nodeRef = useRef(null);

  const height = isActive ? size.height : 0;

  return (
    <Box sx={{ borderBottom: `1px solid ${theme.palette.grey['300']}` }}>
      <Box
        role='button'
        sx={{
          position: 'relative',
          cursor: 'pointer',
          overflow: 'hidden',
          borderRadius: '.75rem',
          '&:hover:before': {
            height: 'calc(100% + 2px)',
            opacity: 1,
          },
          '&:before': {
            transitionProperty: 'all',
            transitionDuration: '300ms',
            transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)',
            content: '""',
            position: 'absolute',
            backgroundColor: theme.palette.primary.main,
            width: '100%',
            height: 0,
            opacity: 0,
            top: 0,
            left: 0,
            zIndex: -1,
          },
        }}
        onClick={toggle}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            fontSize: '20px',
            lineHeight: '28px',
            fontWeight: 'bold',
            padding: '1rem 1.5rem',
            transitionProperty:
              'color, backgroundColor, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter',
            transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)',
            transitionDuration: '300ms',
            '&:hover': {
              color: '#fff',
            },
          }}
        >
          {isActive ? (
            <svg viewBox='0 0 12 2' fill='none' style={{ stroke: 'currentcolor', flex: 'none', width: '.75rem' }}>
              <path d='M11 1H1' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
          ) : (
            <svg viewBox='0 0 14 14' style={{ stroke: 'currentcolor', flex: 'none', width: '.75rem' }}>
              <path d='M7 1V13M13 7H1' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            </svg>
          )}
          <Box sx={{ display: 'flex', flexAuto: '1 1 auto', marginLeft: '1.5rem' }} className='ml-6 flex-auto'>
            {title}
          </Box>
        </Box>
      </Box>
      <Transition
        nodeRef={nodeRef}
        in={isActive}
        timeout={{
          enter: 800,
          exit: 800,
        }}
      >
        {(state) => (
          <Box
            ref={nodeRef}
            sx={{
              overflow: 'hidden',
              transitionProperty: 'height',
              transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)',
              transitionDuration: state === 'entering' || state === 'entered' ? '' : '300ms',
              height,
            }}
          >
            <Box
              ref={sizeRef}
              sx={{
                transitionProperty: 'opacity, transform',
                transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)',
                transitionDuration: '300ms',
                transitionDelay: '300ms',
                opacity: state === 'entering' || state === 'entered' ? 1 : 0,
              }}
            >
              <Box sx={{ padding: '1.5rem', maxWidth: '56rem', color: theme.palette.text.medium }}>{children}</Box>
            </Box>
          </Box>
        )}
      </Transition>
    </Box>
  );
}
