import { KitButton, KitCard, KitCardBody } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@emotion/react';
import AddIcon from '@mui/icons-material/Add';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HomeIcon from '@mui/icons-material/Home';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useIsFetching } from '@tanstack/react-query';
import { DateTime } from 'luxon';
import { useSession } from 'next-auth/react';
import React, { useEffect, useState } from 'react';

// local imports
import { logClientException } from 'appinsights/clientAppInsights';
import PleaseWait from 'components/common/PleaseWait';
import PageContainer from 'components/common/subcomponents/PageContainer';
import RedirectDialog from 'components/common/subcomponents/RedirectDialog';
import { useRouter } from 'next/router';
import { BadRequestError } from 'services/apiWrapper';
import { GRADE_LIST, OBS_ACTIVITY_LIST, OBS_SUBJECT_LIST, OTHER_SCHOOL } from 'services/constants';
import { buttonColor } from 'services/styleUtils/buttonStyleUtils';
import AddEventDialog from './components/AddEventDialog';
import AddTeacherDialog from './components/AddTeacherDialog';
import ObservationHeader from './components/ObservationHeader';
import { ObsStyles } from './components/StyledComponents';
import { useObservationEvents } from './hooks/ObservationEventHooks';
import { useFetchTeachers } from './hooks/TeacherLookupHooks';
import { useObservationStore } from './stores/observationStore';
import { generateUUID } from 'services/stringUtils';
import { getSessionStoreString } from 'services/storageUtils';
import { canAccessObservations, observationRedirectMessage, observationRedirectPage } from './utils/ObservationUtils';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const defaultErrorMessage = 'An unexpected error has occured. Please try again.';

const ObservationStartNew = ({ module: { fields } }) => {
  const { data: session, status } = useSession();
  const theme = useTheme();
  const styles = ObsStyles(theme);
  const router = useRouter();
  const [errorMessage, setErrorMessage] = useState(null);
  const [step, setStep] = useState(getSessionStoreString('step') || '1');

  const [showNewEvent, setShowNewEvent] = useState(false);
  const [showNewTeacher, setShowNewTeacher] = useState(false);
  const [eventList, setEventList] = useState([]);
  const [teacherList, setTeacherList] = useState([]);
  const [eventState, setEventState] = useState({
    event_id: '',
    classroom_type: 'Well-Managed Schools',
    district_id: '',
    district_name: '',
    agency_id: '',
    agency_name: '',
    teacher: '',
    grade: '',
    subject: '',
    number_of_students: '',
    activity: [],
    other_activity: '',
    other_subject: '',
    phys_env_notes: '',
    skills_posters: false,
    classroom_rules: false,
    schoolwide_rules: false,
    classroom_procedures: false,
    schoolwide_procedures: false,
    consequences: false,
    daily_schedule: false,
    cards_on_desk: false,
    students_write_cards: false,
  });

  const eventListQuery = useObservationEvents();
  const teacherListQuery = useFetchTeachers(eventState.agency_id, eventState.district_id);
  const isFetchingCount = useIsFetching();

  const afterCreateObservation = (data) => {
    console.log('afterCreateObservation', data.id);
    router.push({
      pathname: fields.startCTA[0]?.fields?.link?.href,
    });
  };

  const { setObservation } = useObservationStore();

  useEffect(() => {
    if (eventListQuery.isSuccess && eventListQuery.isFetching === false) {
      setEventList(eventListQuery.data || []);
    }
    if (eventListQuery.isError) {
      logClientException(eventListQuery.error);
      if (eventListQuery.error?.message?.toLocaleLowerCase().includes('401')) {
        // not signed in, should get redirect popup
      } else if (eventListQuery.error instanceof BadRequestError) {
        setErrorMessage(eventListQuery.error.formatMessage());
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  }, [eventListQuery]);

  useEffect(() => {
    if (teacherListQuery.isSuccess && teacherListQuery.isFetching === false) {
      let temp = teacherListQuery.data?.slice() || [];
      if (eventState.teacher && !temp.includes(eventState.teacher)) {
        temp.push(eventState.teacher);
        temp.sort();
      }
      setTeacherList(temp);
    }
    if (teacherListQuery.isError) {
      logClientException(teacherListQuery.error);
      if (
        teacherListQuery.error?.message?.toLocaleLowerCase().includes('failed to fetch') ||
        teacherListQuery.error?.message?.toLocaleLowerCase().includes('no-response')
      ) {
        // might be offline, won't have list available.
      } else if (teacherListQuery.error instanceof BadRequestError) {
        setErrorMessage(teacherListQuery.error.formatMessage());
      } else {
        setErrorMessage(defaultErrorMessage);
      }
    }
  }, [teacherListQuery.isSuccess, teacherListQuery.isFetching, teacherListQuery.isError]);

  const resetEventState = () => {
    setEventState({
      event_id: '',
      classroom_type: 'Well-Managed Schools',
      district_id: '',
      district_name: '',
      agency_id: '',
      agency_name: '',
      teacher: '',
      grade: '',
      subject: '',
      number_of_students: '',
      activity: [],
      other_activity: '',
      other_subject: '',
      phys_env_notes: '',
      skills_posters: false,
      classroom_rules: false,
      schoolwide_rules: false,
      classroom_procedures: false,
      schoolwide_procedures: false,
      consequences: false,
      daily_schedule: false,
      cards_on_desk: false,
      students_write_cards: false,
    });
  };

  const updateSelectedEvent = (event) => {
    setTeacherList([]);
    setEventState({
      ...eventState,
      event_id: event.id,
      district_id: event.district_id,
      district_name: event.district_name,
      agency_id: event.agency_id,
      agency_name: event.agency_name,
    });
    eventListQuery.refetch();
  };

  const eventStateOnChange = (key, value) => {
    let canSave = true;
    let fieldError = false;

    if (key === 'number_of_students') {
      if (isNaN(value) || value < 1) {
        canSave = false;
        fieldError = true;
      }
    }
    setEventState({ ...eventState, [key]: value, [`${key}Error`]: fieldError, canSave: canSave });
  };

  const canSaveStep1 = () => {
    let hasError = false;
    const event = eventState;
    if (!event.event_id || event.event_id.length === 0) {
      hasError = true;
      event.event_idError = true;
    } else {
      event.event_idError = false;
    }
    if (!event.classroom_type || event.classroom_type.length === 0) {
      hasError = true;
      event.classroom_typeError = true;
    } else {
      event.classroom_typeError = false;
    }
    if (!event.teacher || event.teacher.length === 0) {
      hasError = true;
      event.teacherError = true;
    } else {
      event.teacherError = false;
    }

    // either grade or subject is required
    if ((!event.grade || event.grade.length === 0) && (!event.subject || event.subject.length === 0)) {
      hasError = true;
      event.gradeError = true;
      event.subjectError = true;
      setErrorMessage('Please specify either a Grade or a Subject.');
    } else {
      event.gradeError = false;
      event.subjectError = false;
    }

    if (event.number_of_students?.length === 0 || isNaN(event.number_of_students) || event.number_of_students < 1) {
      hasError = true;
      event.number_of_studentsError = true;
    } else {
      event.number_of_studentsError = false;
    }
    if (!event.activity || event.activity.length === 0) {
      hasError = true;
      event.activityError = true;
    } else {
      event.activityError = false;
    }
    if (eventState.activity.indexOf('Other') > -1 && (!event.other_activity || event.other_activity.length === 0)) {
      hasError = true;
      event.other_activityError = true;
    } else {
      event.other_activityError = false;
    }

    if (event.subject === 'Other' && (!event.other_subject || event.other_subject.length === 0)) {
      hasError = true;
      event.other_subjectError = true;
    } else {
      event.other_subjectError = false;
    }

    if (hasError) {
      setEventState({ ...event, canSave: false });
    }
    return !hasError;
  };

  const canSaveStep2 = () => {
    // nothing required here?
    return true;
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMessage(null);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleActivityChange = (event) => {
    const {
      target: { value },
    } = event;
    // eventStateOnChange('activity', e.target.value);
    eventStateOnChange(
      'activity',
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.obsBlue.light,
        paddingBottom: '3rem',
      }}
    >
      <ObservationHeader title={fields.title} headerAccentImageUrl={fields.headerAccentImage?.url} />
      <Box>
        <IconButton aria-label='back' href={fields.observationHomeLink.href} title={fields.observationHomeLink.text} color='primary'>
          <KeyboardBackspaceIcon /> <HomeIcon />
        </IconButton>
      </Box>
      <PageContainer>
        <RedirectDialog
          open={(status !== 'loading' && status !== 'authenticated') || !canAccessObservations(session, status)}
          message={observationRedirectMessage(session, status)}
          redirectTo={observationRedirectPage(session, status)}
          buttonText={session?.user ? 'OK' : 'Sign In'}
        />

        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ display: step === '1' ? 'flex' : 'none', flexDirection: 'column', rowGap: '.90rem', maxWidth: '750px', width: '100%' }}>
            <FormControl fullWidth size='small'>
              <TextField
                select
                InputLabelProps={{ shrink: true }}
                sx={styles.select}
                InputProps={{ sx: { ...styles.select } }}
                label='Event'
                labelId='event-select-label'
                id='event-select'
                error={eventState.event_idError}
                size='small'
                value={eventState.event_id}
                required
                onChange={(e) => {
                  if (e.target.value === 'new') {
                    setShowNewEvent(true);
                    return;
                  }
                  const event_id = e.target.value;
                  const event = eventList.find((e) => e.id === event_id);
                  updateSelectedEvent(event);
                }}
              >
                <MenuItem value='' disabled>
                  Choose Event
                </MenuItem>
                {eventList.map((e) => {
                  return (
                    <MenuItem value={e.id} key={e.id} sx={{ display: 'flex', flexDirection: 'column', alignContent: 'flex-start' }} component='div'>
                      <Typography variant='body2' noWrap sx={{ alignSelf: 'flex-start' }}>
                        {e.agency_name === 'Other' ? `${e.district_name} (${e.state})` : `${e.agency_name} - ${e.district_name} (${e.state})`}
                      </Typography>
                      <Typography variant='body2' noWrap sx={{ alignSelf: 'flex-start' }}>
                        {`${DateTime.fromISO(e.start_date).toFormat('MM-dd-yyyy')} - ${DateTime.fromISO(e.end_date).toFormat('MM-dd-yyyy')}`}
                      </Typography>
                    </MenuItem>
                  );
                })}
                {/* 
                
                <MenuItem value='new'>
                  <KitButton
                    size='sm'
                    style={{
                      backgroundColor: theme.palette.gold.main,
                      width: '100%',
                    }}
                  >
                    <span>
                      <AddIcon sx={{ marginTop: '-5px' }} /> Add New
                    </span>
                  </KitButton>
                </MenuItem> */}
              </TextField>
            </FormControl>

            <FormControl fullWidth>
              <RadioGroup
                row={true}
                aria-labelledby='classroom-type-label'
                name='classroom-type'
                value={eventState.classroom_type}
                onChange={(e) => eventStateOnChange('classroom_type', e.target.value)}
                error={eventState.classroom_typeError}
              >
                {['Well-Managed Schools', 'Specialized Classroom Management'].map((c, i) => {
                  return (
                    <FormControlLabel
                      key={c}
                      value={c}
                      control={<Radio color='gold' checkedIcon={<CheckCircleIcon />} icon={<CheckCircleOutlineIcon color='gold' />} />}
                      label={c}
                    />
                  );
                })}
              </RadioGroup>
            </FormControl>

            <FormControl fullWidth>
              <TextField
                InputLabelProps={{ shrink: true }}
                sx={styles.select}
                InputProps={{ sx: { ...styles.select }, readOnly: true }}
                notched={true}
                required={true}
                size='small'
                labelId='agency-label'
                id='agency-value'
                value={eventState.agency_id === OTHER_SCHOOL.id ? eventState.district_name : eventState.agency_name}
                label='Agency/School'
              />
            </FormControl>

            <FormControl fullWidth size='small'>
              <TextField
                select
                InputLabelProps={{ shrink: true }}
                sx={styles.select}
                InputProps={{ sx: { ...styles.select } }}
                label='Teacher'
                labelId='teacher-select-label'
                id='teacher-select'
                error={eventState.teacherError}
                size='small'
                value={eventState.teacher}
                required
                onChange={(e) => {
                  if (e.target.value === 'new') {
                    setShowNewTeacher(true);
                    return;
                  }
                  eventStateOnChange('teacher', e.target.value);
                }}
              >
                <MenuItem value='' disabled>
                  Choose Teacher
                </MenuItem>
                {teacherList.map((e) => {
                  return (
                    <MenuItem value={e} key={e}>
                      {e}
                    </MenuItem>
                  );
                })}
                <MenuItem value='new'>
                  <KitButton
                    size='sm'
                    style={{
                      backgroundColor: theme.palette.gold.main,
                      width: '100%',
                    }}
                  >
                    <span>
                      <AddIcon sx={{ marginTop: '-5px' }} /> Add New
                    </span>
                  </KitButton>
                </MenuItem>
              </TextField>
            </FormControl>

            <FormControl fullWidth size='small'>
              <TextField
                InputLabelProps={{ shrink: true }}
                sx={styles.select}
                inputProps={{ maxLength: 10 }}
                label='Room Number'
                id='room-number'
                size='small'
                value={eventState.room_number}
                onChange={(e) => {
                  eventStateOnChange('room_number', e.target.value);
                }}
              />
            </FormControl>

            {/* <FormControl fullWidth size='small' sx={{ display: eventState.activity.indexOf('Other') > -1 ? 'inline-flex' : 'none' }}>
              <TextField
                InputLabelProps={{ shrink: true }}
                sx={styles.select}
                InputProps={{ sx: { ...styles.select } }}
                inputProps={{ maxLength: 200 }}
                label='Other Activity'
                id='other-activity'
                error={eventState.other_activityError}
                size='small'
                value={eventState.other_activity}
                required
                onChange={(e) => {
                  eventStateOnChange('other_activity', e.target.value);
                }}
              />
            </FormControl> */}

            <FormControl fullWidth size='small'>
              <TextField
                select
                InputLabelProps={{ shrink: true }}
                sx={styles.select}
                InputProps={{ sx: { ...styles.select } }}
                label='Grade'
                id='grade-select'
                error={eventState.gradeError}
                size='small'
                value={eventState.grade}
                onChange={(e) => {
                  eventStateOnChange('grade', e.target.value);
                }}
              >
                <MenuItem value='' disabled>
                  Choose Grade
                </MenuItem>
                {GRADE_LIST.map((e) => {
                  return (
                    <MenuItem value={e.value} key={e.value}>
                      {e.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </FormControl>

            <FormControl fullWidth size='small'>
              <TextField
                select
                InputLabelProps={{ shrink: true }}
                sx={styles.select}
                InputProps={{ sx: { ...styles.select } }}
                label='Subject'
                id='subject-select'
                error={eventState.subjectError}
                size='small'
                value={eventState.subject}
                onChange={(e) => {
                  eventStateOnChange('subject', e.target.value);
                }}
              >
                <MenuItem value='' disabled>
                  Choose Subject
                </MenuItem>
                {OBS_SUBJECT_LIST.map((e) => {
                  return (
                    <MenuItem value={e.value} key={e.value}>
                      {e.name}
                    </MenuItem>
                  );
                })}
              </TextField>
            </FormControl>

            <FormControl fullWidth size='small' sx={{ display: eventState.subject === 'Other' ? 'inline-flex' : 'none' }}>
              <TextField
                InputLabelProps={{ shrink: true }}
                sx={styles.select}
                InputProps={{ sx: { ...styles.select } }}
                inputProps={{ maxLength: 200 }}
                label='Other Subject'
                id='other-subject'
                error={eventState.other_subjectError}
                size='small'
                value={eventState.other_subject}
                required
                onChange={(e) => {
                  eventStateOnChange('other_subject', e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth size='small'>
              <TextField
                InputLabelProps={{ shrink: true }}
                sx={styles.select}
                InputProps={{ sx: { ...styles.select } }}
                inputProps={{ min: 1, max: 1000, type: 'number' }}
                label='Number of Students'
                id='num-students'
                error={eventState.number_of_studentsError}
                size='small'
                value={eventState.number_of_students}
                required
                onChange={(e) => {
                  eventStateOnChange('number_of_students', e.target.value);
                }}
              />
            </FormControl>

            <FormControl fullWidth size='small'>
              <InputLabel id='activity-select-label'>Activity </InputLabel>
              <Select
                id='activity-select'
                labelId='activity-select-label'
                multiple
                InputLabelProps={{ shrink: true }}
                sx={styles.select}
                label='Activity'
                size='small'
                value={eventState.activity}
                onChange={(e) => {
                  eventStateOnChange('activity', e.target.value);
                }}
                error={eventState.activityError}
                required
                input={<OutlinedInput label='Activity' />}
                renderValue={(selected) => selected.join(', ')}
                MenuProps={MenuProps}
              >
                {OBS_ACTIVITY_LIST.map((e) => {
                  return (
                    <MenuItem value={e.value} key={e.value}>
                      <Checkbox checked={eventState.activity.indexOf(e.value) > -1} />
                      <ListItemText primary={e.name} />
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl fullWidth size='small' sx={{ display: eventState.activity.indexOf('Other') > -1 ? 'inline-flex' : 'none' }}>
              <TextField
                InputLabelProps={{ shrink: true }}
                sx={styles.select}
                InputProps={{ sx: { ...styles.select } }}
                inputProps={{ maxLength: 200 }}
                label='Other Activity'
                id='other-activity'
                error={eventState.other_activityError}
                size='small'
                value={eventState.other_activity}
                required
                onChange={(e) => {
                  eventStateOnChange('other_activity', e.target.value);
                }}
              />
            </FormControl>

            {fields.nextCTA[0] && (
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '.5rem' }}>
                <KitButton
                  onClick={() => {
                    if (canSaveStep1()) {
                      setStep('2');
                    }
                  }}
                  size='sm'
                  style={{
                    backgroundColor: buttonColor(theme, fields.nextCTA[0]?.fields?.style?.fields?.color),
                    width: '200px',
                  }}
                >
                  <span>
                    <ArrowRightIcon sx={{ margin: '-7px', width: '32px !important', height: '32px !important' }} /> {fields.nextCTA[0]?.fields?.label}
                  </span>
                </KitButton>
              </Box>
            )}
          </Box>

          <Box sx={{ display: step === '2' ? 'flex' : 'none', flexDirection: 'column', rowGap: '.90rem' }}>
            <KitCard>
              <KitCardBody style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '.2rem' }}>
                  <Typography sx={{ fontSize: '24px', fontWeight: 600, lineHeight: '30px', color: theme.palette.primary.dark, mb: '1rem' }}>
                    Physical Environment Checklist
                  </Typography>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={eventState.skills_posters}
                        onChange={(e) => eventStateOnChange('skills_posters', e.target.checked)}
                        color='gold'
                        checkedIcon={<CheckCircleIcon />}
                        icon={<CheckCircleOutlineIcon color='gold' />}
                      />
                    }
                    label='BT Skills Posters'
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={eventState.classroom_rules}
                        onChange={(e) => eventStateOnChange('classroom_rules', e.target.checked)}
                        color='gold'
                        checkedIcon={<CheckCircleIcon />}
                        icon={<CheckCircleOutlineIcon color='gold' />}
                      />
                    }
                    label='Classroom Rules'
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={eventState.schoolwide_rules}
                        onChange={(e) => eventStateOnChange('schoolwide_rules', e.target.checked)}
                        color='gold'
                        checkedIcon={<CheckCircleIcon />}
                        icon={<CheckCircleOutlineIcon color='gold' />}
                      />
                    }
                    label='School-wide Rules'
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={eventState.classroom_procedures}
                        onChange={(e) => eventStateOnChange('classroom_procedures', e.target.checked)}
                        color='gold'
                        checkedIcon={<CheckCircleIcon />}
                        icon={<CheckCircleOutlineIcon color='gold' />}
                      />
                    }
                    label='Classroom Procedures'
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={eventState.schoolwide_procedures}
                        onChange={(e) => eventStateOnChange('schoolwide_procedures', e.target.checked)}
                        color='gold'
                        checkedIcon={<CheckCircleIcon />}
                        icon={<CheckCircleOutlineIcon color='gold' />}
                      />
                    }
                    label='School-wide Procedures'
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={eventState.consequences}
                        onChange={(e) => eventStateOnChange('consequences', e.target.checked)}
                        color='gold'
                        checkedIcon={<CheckCircleIcon />}
                        icon={<CheckCircleOutlineIcon color='gold' />}
                      />
                    }
                    label='Consequences'
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={eventState.daily_schedule}
                        onChange={(e) => eventStateOnChange('daily_schedule', e.target.checked)}
                        color='gold'
                        checkedIcon={<CheckCircleIcon />}
                        icon={<CheckCircleOutlineIcon color='gold' />}
                      />
                    }
                    label='Daily schedule/agenda'
                  />

                  {eventState.classroom_type === 'Specialized Classroom Management' && (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={eventState.cards_on_desk}
                            onChange={(e) => eventStateOnChange('cards_on_desk', e.target.checked)}
                            color='gold'
                            checkedIcon={<CheckCircleIcon />}
                            icon={<CheckCircleOutlineIcon color='gold' />}
                          />
                        }
                        label='Cards on desk'
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={eventState.students_write_cards}
                            onChange={(e) => eventStateOnChange('students_write_cards', e.target.checked)}
                            color='gold'
                            checkedIcon={<CheckCircleIcon />}
                            icon={<CheckCircleOutlineIcon color='gold' />}
                          />
                        }
                        label='Students write on their cards'
                      />
                    </>
                  )}

                  <FormControl fullWidth size='small'>
                    <TextField
                      placeholder='Enter notes here...'
                      multiline={true}
                      rows={4}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ maxLength: 500 }}
                      sx={{ ...styles.select, marginTop: '.75rem' }}
                      InputProps={{ sx: { ...styles.select } }}
                      label='Physical Environment Notes'
                      id='phys-env-notes'
                      error={eventState.phys_env_notesError}
                      size='small'
                      value={eventState.phys_env_notes}
                      onChange={(e) => {
                        eventStateOnChange('phys_env_notes', e.target.value);
                      }}
                    />
                  </FormControl>
                </Box>
              </KitCardBody>
            </KitCard>

            {fields.startCTA[0] && (
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '.5rem' }}>
                <KitButton
                  onClick={() => {
                    if (canSaveStep2()) {
                      const observation = { ...eventState, id: generateUUID() };
                      setObservation(observation);
                      resetEventState();
                      afterCreateObservation(observation);
                    }
                  }}
                  size='sm'
                  style={{
                    backgroundColor: buttonColor(theme, fields.startCTA[0]?.fields?.style?.fields?.color),
                    width: '200px',
                  }}
                >
                  <span>
                    <ArrowRightIcon sx={{ margin: '-7px', width: '32px !important', height: '32px !important' }} />{' '}
                    {fields.startCTA[0]?.fields?.label}
                  </span>
                </KitButton>
              </Box>
            )}
          </Box>
        </Box>

        <AddEventDialog
          user={session?.user}
          open={showNewEvent}
          onClose={(save, event) => {
            if (save) {
              updateSelectedEvent(event);
            }
            setShowNewEvent(false);
          }}
        />
        <AddTeacherDialog
          open={showNewTeacher}
          onClose={(save, teacher) => {
            if (save) {
              let temp = teacherList.slice();
              temp.push(teacher);
              temp.sort();
              setTeacherList(temp);
              eventStateOnChange('teacher', teacher);
            }
            setShowNewTeacher(false);
          }}
        />
        <Snackbar open={errorMessage} onClose={handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>

        <PleaseWait isLoading={isFetchingCount > 0 || teacherListQuery.isFetching || eventListQuery.isFetching} />

        {/* <pre>{JSON.stringify(fields, null, 2)}</pre> */}
      </PageContainer>
    </Box>
  );
};

export default ObservationStartNew;
