// package imports
import { KitButton } from '@boystownorg/bi-cms-component-lib';
import { useTheme } from '@emotion/react';
import CheckIcon from '@mui/icons-material/Check';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Box, FormControl, TextField } from '@mui/material';
import { useEffect, useState } from 'react';

// local imports
import ConfirmDeleteDialog from 'components/common/ConfirmDeleteDialog';
import { useObservationStore } from '../../stores/observationStore';
import { ObsStyles } from '../StyledComponents';

const ObservationOffTaskEdit = ({ onComplete, onDirty }) => {
  const theme = useTheme();
  const styles = ObsStyles(theme);
  const { updateTimelineObservation, removeTimelineObservation, editTimelineItem, setEditTimelineItem } = useObservationStore();

  const [showOffTask, setShowOffTask] = useState(false);
  const [offTaskNumber, setOffTaskNumber] = useState('');
  const [numStudents, setNumStudents] = useState(0);
  const [offTaskNumberError, setOffTaskNumberError] = useState(null);

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);

  useEffect(() => {
    if (editTimelineItem?.type === 'Off Task') {
      setOffTaskNumber(Number.parseInt(editTimelineItem.count, 10));
      setNumStudents(Number.parseInt(editTimelineItem.number_of_students, 10));
    } else {
      resetState();
    }
  }, [editTimelineItem]);

  const resetState = () => {
    setOffTaskNumber('');
    setOffTaskNumberError(null);
    setConfirmDeleteOpen(false);
  };

  const addToTimeline = (isClosed = false) => {
    const timelineNote = {
      timestamp: editTimelineItem.timestamp,
      type: 'Off Task',
      tags: [isClosed ? 'X' : offTaskNumber],
      count: isClosed ? -1 : Number.parseInt(offTaskNumber, 10),
      number_of_students: editTimelineItem.number_of_students,
    };
    updateTimelineObservation(timelineNote);
    setOffTaskNumber('');
    setOffTaskNumberError(false);
    setShowOffTask(false);
    onComplete();
  };

  const deleteTimelineObservation = () => {
    removeTimelineObservation(editTimelineItem);
    resetState();
    onComplete();
  };

  return (
    <>
      <Box style={{ width: '80%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <Box style={{ width: '75%' }}>
          <FormControl fullWidth size='small'>
            <TextField
              placeholder='Enter a number'
              InputLabelProps={{ shrink: true }}
              sx={{ ...styles.select, marginTop: '.75rem' }}
              InputProps={{ sx: { ...styles.select } }}
              inputProps={{ min: 0, max: numStudents, type: 'number' }}
              label='Number of Off-Task Students'
              id='off-task-students'
              error={offTaskNumberError}
              size='small'
              value={offTaskNumber}
              onChange={(e) => {
                setOffTaskNumber(e.target.value);
                if (isNaN(e.target.value) || e.target.value < 0) {
                  setOffTaskNumberError(true);
                } else {
                  setOffTaskNumberError(false);
                }
              }}
            />
          </FormControl>
        </Box>
        <Box sx={{ marginTop: '.5rem', display: 'flex', justifyContent: 'center', flexDirection: 'column', width: '85%' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <KitButton
              onClick={(e) => addToTimeline(false)}
              size='sm'
              style={{
                backgroundColor: theme.palette.gold.main,
                marginTop: '1rem',
              }}
              disabled={offTaskNumber === 0}
            >
              <span>
                <CheckIcon sx={{ marginRight: '.5rem', marginTop: '-5px', width: '16px !important', height: '16px !important' }} /> Save
              </span>
            </KitButton>
            <KitButton
              onClick={() => setConfirmDeleteOpen(true)}
              size='sm'
              style={{
                backgroundColor: theme.palette['obsOff Task'].main,
                marginTop: '1rem',
              }}
            >
              <span>
                <DeleteForeverIcon sx={{ marginRight: '.5rem', marginTop: '-5px', width: '16px !important', height: '16px !important' }} />
                Delete
              </span>
            </KitButton>
          </Box>
        </Box>
      </Box>
      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        confirmMessage='Are you sure you want to delete this Off-Task count?'
        handleClose={() => setConfirmDeleteOpen(false)}
        handleYes={deleteTimelineObservation}
        noButtonColor={theme.palette.obsAqua.main}
      />
    </>
  );
};

export default ObservationOffTaskEdit;
