import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { Box, Dialog, DialogContent, DialogTitle, FormControl, IconButton, TextField } from '@mui/material';
import DraggablePaperComponent from 'components/common/subcomponents/DraggablePaperComponent';
import CloseIcon from '@mui/icons-material/Close';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

const OffTaskDialog = (props, styles) => {
  const theme = useTheme();

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      closeConfirm();
    }
    return;
  };

  return (
    <Dialog scroll='body' fullwidth onClose={handleClose} aria-labelledby='off-task-dialog' open={props.open}>
      <DialogTitle sx={{ backgroundColor: theme.palette['obsOff Task'].main, color: theme.palette.common.white, padding: '8px 16px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <span>
            <PriorityHighIcon
              style={{ marginLeft: '10px', marginTop: '-3px', fontSize: '1.75rem', fontWeight: 600, color: theme.palette.common.white }}
            />{' '}
            Off Task Students
          </span>
          <IconButton
            onClick={() => {
              props.addToTimeline(true);
            }}
            sx={{ marginLeft: '3px' }}
            className='need-interaction'
          >
            <CloseIcon style={{ height: '30px', width: '30px', color: theme.palette.common.white }} />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent sx={{ marginTop: '1rem', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ width: '250px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <FormControl size='small' sx={{ marginLeft: '1rem' }}>
            <TextField
              placeholder='Enter a number'
              InputLabelProps={{ shrink: true }}
              sx={{ ...styles.select, marginTop: '.75rem', width: '160px' }}
              InputProps={{ sx: { ...styles.select } }}
              inputProps={{ min: 0, max: props.observation && props.observation.number_of_students, type: 'number' }}
              id='off-task-students'
              error={props.offTaskNumberError}
              size='small'
              value={props.offTaskNumber}
              onChange={(e) => {
                props.setOffTaskNumber(e.target.value);
                if (isNaN(e.target.value) || e.target.value < 0) {
                  props.setOffTaskNumberError(true);
                } else {
                  props.setOffTaskNumberError(false);
                }
              }}
            />
          </FormControl>
          <Box>
            <IconButton
              disabled={props.offTaskNumberError}
              className='need-interaction'
              onClick={() => props.addToTimeline(false)}
              style={{ marginTop: '.5rem' }}
            >
              <ArrowCircleRightIcon sx={{ color: theme.palette.gold.main }} fontSize='large' />
            </IconButton>
          </Box>
        </Box>
      </DialogContent>
      {/* <DialogActions sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}></DialogActions> */}
    </Dialog>
  );
};

OffTaskDialog.propTypes = {
  styles: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  closeConfirm: PropTypes.func.isRequired,
  observation: PropTypes.object.isRequired,
  offTaskNumber: PropTypes.string.isRequired,
  setOffTaskNumber: PropTypes.func.isRequired,
  offTaskNumberError: PropTypes.object.isRequired,
  setOffTaskNumberError: PropTypes.func.isRequired,
  addToTimeline: PropTypes.func.isRequired,
};

export default OffTaskDialog;

// export default function CustomizedDialogs(props) {
//   const { isOpen, onClose } = props;
//   const [open, setOpen] = React.useState(isOpen);

//   React.useEffect(() => {
//     setOpen(isOpen);
//   }, [isOpen]);

//   const handleClose = (event, reason) => {
//     if (reason !== 'backdropClick') {
//       setOpen(false);
//       onClose(false);
//     }
//     return;
//   };

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   return (
//     <React.Fragment>
//       <BootstrapDialog
//         onClose={handleClose}
//         aria-labelledby='customized-dialog-title'
//         open={open}
//         PaperComponent={DraggablePaperComponent}
//         hideBackdrop={true}
//         PaperProps={{ style: { pointerEvents: 'auto' } }}
//       >
//         <DialogTitle sx={{ m: 0, p: 2 }} id='customized-dialog-title'>
//           Modal title
//         </DialogTitle>
//         <IconButton
//           aria-label='close'
//           onClick={handleClose}
//           sx={{
//             position: 'absolute',
//             right: 8,
//             top: 8,
//             color: (theme) => theme.palette.grey[500],
//           }}
//         >
//           <CloseIcon />
//         </IconButton>
//         <DialogContent dividers>
//           <Typography gutterBottom>
//             Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
//             consectetur ac, vestibulum at eros.
//           </Typography>
//           <Typography gutterBottom>
//             Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.
//           </Typography>
//           <Typography gutterBottom>
//             Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui.
//             Donec ullamcorper nulla non metus auctor fringilla.
//           </Typography>
//         </DialogContent>
//         <DialogActions>
//           <Button autoFocus onClick={handleClose}>
//             Save changes
//           </Button>
//         </DialogActions>
//       </BootstrapDialog>
//     </React.Fragment>
//   );
// }
