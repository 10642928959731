import styled from '@emotion/styled';
import { FreeformDelimiter } from './FreeformDelimiter';
import { FreeformHeader } from './FreeformHeader';
import { FreeformImage } from './FreeformImage';
import { FreeformList } from './FreeformList';
import { FreeformParagraph } from './FreeformParagraph';
import { FreeformQuote } from './FreeformQuote';
import { FreeformRaw } from './FreeformRaw';
import { FreeformTable } from './FreeformTable';
import { FreeformWarning } from './FreeformWarning';

const components = new Map([
  ['delimiter', FreeformDelimiter],
  ['header', FreeformHeader],
  ['image', FreeformImage],
  ['list', FreeformList],
  ['paragraph', FreeformParagraph],
  ['raw', FreeformRaw],
  ['quote', FreeformQuote],
  ['table', FreeformTable],
  ['warning', FreeformWarning],
]);

export function Freeform({ blocks }) {
  return (
    <div
      suppressHydrationWarning
      // className={cx(
      //     '[&_a]:underline [&_a]:underline-offset-4 [&_a]:transition [&_a]:duration-150 ',
      //     '[&_a]:text-blue [&_a:hover]:text-black',
      //     'dark:[&_a]:text-yellow-tint dark:[&_a:hover]:text-white'
      // )}
    >
      {blocks.map(({ id, type, data }) => {
        const Component = components.get(type);

        if (!Component) {
          console.warn(`Component "${type}" not found`);

          if (process.env.NODE_ENV === 'development') {
            return <div key={id}>Freeform component [{type}] not found</div>;
          }

          return null;
        }

        return (
          <FreeFormWrapper key={id}>
            <Component {...data} />
          </FreeFormWrapper>
        );
      })}
    </div>
  );
}

const FreeFormWrapper = styled.div`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  '& :first-child': {
    margin-top: 0;
  }
  '& :last-child': {
    margin-bottom: 0;
  }
`;
