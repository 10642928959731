// package imports
import { useQuery } from '@tanstack/react-query';

// local imports
import { callApi } from 'services/apiWrapper';

export const observerLookupKeys = {
  observerList: () => ['observerList'],
};

export const fetchObservers = async () => {
  const res = await callApi(`/api/db/observation/observer-list`);
  return res.map((o) => ({ label: `${o.lastName}, ${o.firstName}`, value: o.emailAddress }));
};

export const useFetchObservers = () => {
  return useQuery({
    queryKey: observerLookupKeys.observerList(),
    queryFn: () => fetchObservers(),
    networkMode: 'offlineFirst',
    retry: false,
  });
};
