import React from 'react';
import Head from 'next/head';
import parse from 'html-react-parser';

const SEO = ({ title, description, keywords, ogImage, metaHTML, additionalHeaderMarkup }) => {
  // setup and parse additional header markup
  let addNoRobots = process.env.NEXT_PUBLIC_NO_ROBOTS;
  let additionalMetaHtml = null;
  if (metaHTML) {
    additionalMetaHtml = parse(metaHTML);
    if (metaHTML.indexOf('robots') > -1 && metaHTML.indexOf('noindex') > -1) {
      // no robots already present
      addNoRobots = false;
    }
  }
  let additionalHeaderMarkupParsed = null;
  if (additionalHeaderMarkup) {
    // additionalHeaderMarkupParsed = parse(additionalHeaderMarkup, {
    //   replace: (domNode) => {
    //     if (domNode.type === 'script' && typeof window !== 'undefined') {
    //       var script = document.createElement('script');
    //       if (domNode?.attribs?.src) {
    //         script.src = domNode?.attribs.src;
    //       }
    //       if (domNode?.attribs?.type) {
    //         script.type = domNode?.attribs.type;
    //       }
    //       if (domNode?.attribs?.id) {
    //         script.id = domNode?.attribs.id;
    //         document.getElementById(domNode?.attribs.id)?.remove();
    //       }
    //       script.textContent = domNode?.children[0]?.type === 'text' ? domNode?.children[0]?.data : '';
    //       document.head.appendChild(script);
    //     }
    //   },
    // });
    additionalHeaderMarkupParsed = parse(additionalHeaderMarkup);
  }
  return (
    <Head>
      <title>{`${title} | Boys Town National Training`}</title>
      {addNoRobots && <meta name='robots' content='noindex' />}
      <meta name='generator' content='Agility CMS' />
      <meta name='agility_timestamp' content={new Date().toLocaleString()} />
      <meta name='viewport' content='initial-scale=1.0, width=device-width' />
      <meta name='description' content={description} />
      <meta name='keywords' content={keywords} />
      <link href='https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;900&display=swap' rel='stylesheet' />
      {ogImage && <meta property='og:image' content={ogImage} />}
      {additionalMetaHtml}
      {additionalHeaderMarkupParsed}
    </Head>
  );
};

export default SEO;
